.btn-scrollTop{
    position: fixed;
    bottom: 70px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #2d3748;
    right: 2%;
    background: none;
    border-radius: 50px;
    padding: 0px;
    border: none;
    opacity: 0.7;
}